<template>
  <div class="app-container" v-loading="fetchLoading">
    <div class="top_btns clearfix">
      <div class="fr">
        <qzf-video vid="4467be537414c3dbb26ad6b2d6fa1be4_4"></qzf-video>
        
        <qzf-button @success="generalTax" size="small" type="success"   >
          <el-icon><HelpFilled /></el-icon><span  > 取数</span>
        </qzf-button>
        <qzf-button jz="false" @success="getList" size="small" type="primary"   >
          <el-icon><Refresh /></el-icon><span  > 刷新</span>
        </qzf-button>
        <qzf-button jz="false" @success="shenbao" size="small" type="primary"   >
          <el-icon><Tickets /></el-icon><span  > 申报</span>
        </qzf-button>
        <qzf-button @success="handleSubject" size="small" type="primary"   >
          <el-icon><Setting /></el-icon><span  > 计提科目设置</span>
        </qzf-button>
        <settings :type="['code_jjdj','code_jjdj_subject_code','code_sffgs','code_xgmdkfpbjsfjs','code_jjdj_kcs','code_jm_tax','code_vat_bc','code_vat_ckts','code_yhsfu','code_yh_in','code_qysds','code_gxqy','code_sds_ms','code_fgs','code_ghjf','code_ghjf_gz','code_ghjf_0','code_yh0','code_yhsjsjshj','code_sl_0' ]"></settings>
      </div>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" class="top_nav" type="border-card">

      <!-- 必报列表 -->
      <el-tab-pane label="必报列表" name="first">
        <el-table stripe :header-cell-style="{background: '#eee',color:'#333','font-size': '12px'}" :data="list" style="width: 100%;" v-loading="loading" border :height="contentStyleObj" @selection-change="handleSelectionChange">
          <template #empty>
            <el-empty :image-size="150" description="没有数据"></el-empty>
          </template>
          <el-table-column prop="name" type="selection" width="55" align="center"></el-table-column>
          <el-table-column prop="name" label="申报列表名称（财务报表需单独取数）" min-width="250">
            <template #default="scope">
              <span>{{$tableNameFilter(scope.row.tableName)}}</span>
              <!-- <span style="margin-left:10px;color:#3c6ff2;cursor:pointer" @click="declareDetail(scope.row)">申报详情</span>
              <span style="margin-left:10px;color:#3c6ff2;cursor:pointer" @click="deductionDetail(scope.row)">扣款详情</span> -->
            </template>
          </el-table-column>
          <el-table-column prop="name" label="取数状态" align="center" min-width="90">
            <template #default="scope">
              <div class="item_icon" v-if="scope.row.status == 1">
                <i class="iconfont icon-duihao"></i>
                <p>已取数</p>
              </div>
              <div class="item_icon" v-else>
                <i class="iconfont icon-gantanhao"></i>
                <p>未取数</p>
              </div>
            </template>
          </el-table-column>
           <el-table-column prop="name" label="取数时间" min-width="155" align="center">
            <template #default="scope">
              <span>{{$parseTime(scope.row.createdAt,'{y}-{m}-{d} {h}:{i}:{s}')}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="taskStatus" label="申报状态" align="center" min-width="220">
            <template #default="scope">
              <el-tooltip placement="top" :content="scope.row.errLog?scope.row.errLog:'' + scope.row.businessLog?scope.row.businessLog:''" v-if="scope.row.tableName == 'gs_gs' || scope.row.tableName == 'gs_gs_one'">
                <div class="item_icon">
                  <span v-if="scope.row.image" class="demo-image__preview" >
                    <i class="iconfont icon-picture" style="color:#409eff;font-size:16px;" @click="open(scope.row, scope.$index)"></i>
                    {{$setUpStatusGsSb(scope.row.taskStatus,scope.row.businessStatus)}}
                  </span>
                  <p v-else>
                    <i  :class="$setUpStatusGsIconSb(scope.row.taskStatus,scope.row.businessStatus)"></i>
                    <el-button size="small" class="sbcg" style="border: 0;">{{$setUpStatusGsSb(scope.row.taskStatus,scope.row.businessStatus)}}</el-button>
                  </p>
                </div>
              </el-tooltip>

              <el-tooltip placement="top" :content="scope.row.errLog?scope.row.errLog:'' + scope.row.businessLog?scope.row.businessLog:''" v-else>
                <p class="item_icon">
                  <i :class="$batchSendIconSb(scope.row.taskStatus, scope.row.businessStatus)"></i>
                  <el-button size="small" class="sbcg" style="border: 0;">{{$batchSendSb(scope.row.taskStatus,scope.row.businessStatus)}}</el-button>
                </p>
              </el-tooltip>

            </template>
          </el-table-column>
          <el-table-column prop="name" label="申报详情" min-width="120" align="center">
            <template #default="scope">
              <span style="color:#3c6ff2;cursor:pointer" @click="declareDetail(scope.row)">点击查看</span>
            </template>
          </el-table-column>


          <el-table-column prop="name" label="扣款状态" align="center" min-width="110">
            <template #default="scope">
              <el-tooltip placement="top" :content="scope.row.errLog2?scope.row.errLog2:'' + scope.row.businessStatus2?scope.row.businessStatus2:''">
                <p class="item_icon">
                  <i :class="$batchSendIconSb(scope.row.taskStatus2, scope.row.businessStatus2)"></i>
                  <el-button size="small" class="sbcg" style="border: 0;">{{$batchSendKk(scope.row.taskStatus2,scope.row.businessStatus2)}}</el-button>
                </p>
              </el-tooltip>
            </template>
          </el-table-column>


          <el-table-column prop="name" label="扣款详情" min-width="120" align="center">
            <template #default="scope">
              <span style="color:#3c6ff2;cursor:pointer" @click="deductionDetail(scope.row)">点击查看</span>
            </template>
          </el-table-column>
         
          <el-table-column prop="name" label="税款" align="center" min-width="160">
            <template #default="scope">
              <span v-if="scope.row.tax && scope.row.tax != '0'">{{scope.row.tax}}
                <span v-if="scope.row.tax_fjs && scope.row.tax_fjs != '0'"> + {{scope.row.tax_fjs}}</span>
                <span v-if="scope.row.taxOnly && scope.row.taxOnly != '0'"> + {{scope.row.taxOnly}}</span>
              </span>
              <span v-else>
                {{0}}
                <span v-if="scope.row.tax_fjs && scope.row.tax_fjs != '0'"> + {{scope.row.tax_fjs}}</span>
                <span v-if="scope.row.taxOnly && scope.row.taxOnly != '0'"> + {{scope.row.taxOnly}}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="税负率" align="center" min-width="80">
            <template #default="scope">
              <span v-if="scope.row.sf">{{scope.row.sf}}</span>
              <span v-else>{{0}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="年税负率" align="center" min-width="80">
            <template #default="scope">
              <span v-if="scope.row.sfYear">{{scope.row.sfYear}}</span>
              <span v-else>{{0}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" class-name="top_right_btns" min-width="150">
            <template #default="scope">
              <div class="item_icon" @click="editTax(scope.row)" v-if="scope.row.tableName != 'gs_gs_one'">
                <!-- <i class="el-icon-view"></i> -->
                <p style="color:#3c6ff2">查看</p>
              </div>
              <div class="item_icon" v-if="scope.row.tableName != 'gs_gs_one' && scope.row.tableName != 'gs_gs' && scope.row.tableName != 'gs_cwbb'">
                <el-button size="" type="text" @click="fetchNumber(scope.row)">取数</el-button>
              </div>
              <!-- 财务报表取数 -->
              <div class="item_icon" v-if="scope.row.tableName == 'gs_cwbb'">
                <el-button size="" type="text" @click="cwbbFetchNumber(scope.row)">取数</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <!-- 必报列表 end -->
    </el-tabs>
  </div>
  <provision ref="provision"></provision>
  <declareDetails ref="declareDetails"></declareDetails>
  <sbImgUrl ref="sbImgUrl"></sbImgUrl>

</template>

<script>
import { taxList , taxCreate ,createKj} from "@/api/taxCalculation"
import provision from './vat-invoice/component/provision.vue'
import declareDetails from "../../batch/components/declareDetails.vue"
import { sendTask } from "@/api/collection"
import sbImgUrl from "../../batch/components/sbImgUrl.vue"

export default {
  name:'tax',
  components: { 
    provision,
    declareDetails,
    sbImgUrl },
  data() {
    return {
      list:[],
      activeName:'first',
      loading:false,
      fetchLoading:false,
      period:this.$store.getters['user/comInfo'].period,
      gsMainIds:undefined
    }
  },
  deactivated(){
    if(!this.$getCachedViews("tax")){//销毁 mitt bus
      this.$bus.off("taxUpdate")
    }
  },
  created(){
    this.getList()
    this.initBus()
    this.contentStyleObj= this.$getHeight(300)
  },
  methods:{
    initBus(){
      this.$bus.on("taxUpdate", (val) => {
        this.getList()
      });
    },
    editTax(row){
      if(row.status != 1){
        this.$qzfMessage("请先取数", 1)
        return
      }
      // this.$router.push({
      //   // path: "/report/smallVat",  //小规模
      //   // path: "/report/qysds",     //企业所得税
      //   path: "/report/vatInvoice",   //一般纳税人
      // });
      if(row.tableName == 'kj_lrb'|| row.tableName == 'kj_zcfz'|| row.tableName == 'gs_cwbb'){
          this.$router.push({
          path: "/report/finance",   //会计报表
        });
      }else if(row.tableName == 'gs_vat'){
          this.$router.push({
          path: "/report/vatInvoice",   //增值税报表 一般纳税人
        });
      }else if(row.tableName == 'gs_small_vat'){
          this.$router.push({
          path: "/report/smallVat",   //增值税报表 小规模
        });
      }else if(row.tableName == 'gs_tax_quarter'){
          this.$router.push({
          path: "/report/qysds",   //企业所得税
        });
      }else if(row.tableName == 'gs_deed'){
          this.$router.push({
          path: "/report/propertyTax",   //财产和行为纳税申报表
        });
      }else if(row.tableName == 'gs_qtsr'){
          this.$router.push({
          path: "/report/taxNeimengQtsr",   //其他收入
        });
      }else if(row.tableName == 'gs_sl'){
          this.$router.push({
          path: "/report/taxNeimengSl",   //水利建设专项收入
        });
      }else if(row.tableName == 'gs_ljcl'){
          this.$router.push({
          path: "/report/taxCityLife",   //城市生活垃圾处置费
        });
      }else if(row.tableName == 'gs_xfs'){
          this.$router.push({
          path: "/report/xfs",   //城市生活垃圾处置费
        });
      }else if(row.tableName == 'gs_cbj'){
          this.$router.push({
          path: "/report/cbj",   //残疾人就业保障金申报表
        });
      }else if(row.tableName == 'gs_whsyjsfsbbygz'){
          this.$router.push({
          path: "/report/culturalTax",   //残疾人就业保障金申报表
        });
      }else if(row.tableName == 'gs_gs'){
          this.$router.push({
          path: "/report/normal",   //综合所得个人所得税预扣预缴申报表
        });
      }
    },
    handleSubject() {
      this.$refs.provision.openDialog()
    },
    handleSelectionChange(row) {
      this.gsMainIds = []
      row.map(info=>{
        // this.gsMainIds.push({gsMainId: item.gsMainId, gsMainStatus: item.gsMainStatus})
        this.gsMainIds.push(info.id)
      })
    },
    //申报
    shenbao() {
      if(!this.gsMainIds){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let param = {
        taskName: 'tax_sb',
        gsMainIds: this.gsMainIds
      }
      sendTask(param).then(res => {
        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)

        }
      })
    },
    getList() {
      this.fetchLoading = true
      taxList({}).then(res=>{
        this.fetchLoading = false
        if(res.data.data.list){
          this.list = res.data.data.list
        }else{
          this.list = []
        }      
      })
    },
    // 取数
    fetchNumber(row){
      this.fetchLoading = true
      taxCreate({tableName:row.tableName}).then(res=>{
        this.fetchLoading = false
        if(res.data.msg == "success"){
          this.getList();
          this.$qzfMessage("取数成功")
          this.$bus.emit('voucherUpdate')
        }
      })
    },
    // 财务报表取数
    cwbbFetchNumber(){
      this.fetchLoading = true
      let param = {
        zcfz:1,
        lrb:1,
        xjllb:1
      }
      createKj(param).then(res=>{
        if(res.data.data.subjectStatus == true){
          this.$notify.error({
            title: '提示',
            message: '银行现金金额小于0',
            duration: 0
          });
        }
        if(res.data.data.zcLessThen0 == true){
          this.$notify.error({
            title: '提示',
            message: '银行现金金额小于0',
            duration: 0
          });
        }
        if(res.data.data.zcfzStatus == true){
          this.$notify.error({
            title: '提示',
            message: '资产负债不平衡',
            duration: 0
          });
        }
        this.fetchLoading = false
        if(res.data.msg == "success"){
          this.getList();
          this.$qzfMessage("操作成功")
          this.$bus.emit('voucherUpdate')

        }
      })
    },
    generalTax(){
      this.fetchLoading = true
      taxCreate({}).then(res=>{
        this.fetchLoading = false
        if(res.data.msg == "success"){
          this.getList()
          this.$bus.emit('voucherUpdate')
          this.$qzfMessage("操作成功")
        }
      })
    },
    declareDetail(e) {
      this.$refs.declareDetails.init(e.comId,this.period,e.taskName)
    },
    deductionDetail(e) {
      this.$refs.declareDetails.init(e.comId,this.period,e.taskName2)
    },
    // 个税申报图展示
    open(row, index) {
      let imageAll = []
      this.list.map(res => {
        let url = {
          name: res.name,
          url: ""
        }
        url.url = res.image ? 'https://file.listensoft.net' + res.image + "?" + Math.random(10): ""
        imageAll.push(url)
      })
      this.$refs.sbImgUrl.dialog(imageAll, index)
    },
  }
}
</script>

<style lang="scss" scoped>
.top_right_btns {
  i {
    // margin: 0 5px;
    cursor: pointer;
    color: $color-main;
    font-size: 18px;
    // line-height: 24px;
  }
  div{
    margin-right:5px
  }
}
.top_btns {
  margin-bottom: 10px;
  position: relative;
  display: inline;
  top: 35px;
  right: 15px;
  z-index: 999;
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 28px;
    font-size: 16px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }

  p{
    display: inline-block;
    font-size: 14px;
    line-height: 28px;
    color: #333;
  }
}
</style>